import React, { useEffect, useContext, useState } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import classnames from "classnames";
import Seo from "components/seo";
import Layout from "layouts/zh";
import { Languages } from "i18n/config";
import { saTrackForresterLoginClick } from 'utils/statistics'
import { isBrowser, isPc } from 'utils/utils'
import { providerContext } from 'components/Provider'
import * as styles from "./index.module.less"

let SensorsLogin: any

const Report: React.FC = (props) => {
  const { allForresterYaml: { nodes } } = useStaticQuery(graphql`
  query {
    allForresterYaml {
      nodes {
        title
        description
        keywords
        saTitle
        forresterTitle
        forresterDesc
        forresterImg
        forresterTitleMb
        forresterImgMb
      }
    }
  }
`)

  const [userUpdate, setUserUpdate] = useState<boolean>(false);

  return (
    <Layout {...props} userUpdate={userUpdate}>
      <ReportState data={nodes[0]} userUpdateChange={(val) => setUserUpdate(val)} />
    </Layout>
  );
};

interface ReportStateProps {
  data?: any;
  lang?: Languages;
  userUpdateChange?: (val: boolean) => void;
}
export const ReportState: React.FC<ReportStateProps> = (props) => {
  const { data, userUpdateChange } = props;
  let { user } = useContext(providerContext)
  
  const handleForresterSuccess = (res, val) => {
    saTrackForresterLoginClick(val)
    userUpdateChange && userUpdateChange(true)
    if(isPc()){
      window.open('https://reprints2.forrester.com/#/assets/2/2455/RES178890/report')
    } else {
      window.location.href = 'https://reprints2.forrester.com/#/assets/2/2455/RES178890/report'
    }
  }

  const onSignedClick  = () => {
    saTrackForresterLoginClick(false)
    if(isPc()){
      window.open('https://reprints2.forrester.com/#/assets/2/2455/RES178890/report')
    } else {
      window.location.href = 'https://reprints2.forrester.com/#/assets/2/2455/RES178890/report'
    }
  }

  const showLogin = () => {
    if (!SensorsLogin) {
      SensorsLogin = require('@eefe/sensors-login/dist/sensors-login.js')
      require('@eefe/sensors-login/dist/sensors-login.css')
    }
    let dom;
    if (isPc()){
      dom = document.getElementById('forester_login')
    }else {
      dom = document.getElementById('forester_login_mb')
    }
    SensorsLogin?.renderLoginForrester(
      {
        lang: '', // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
        domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
        // env: 'development',  // 测试环境配置
        submitButtonText: "免费阅读报告",
        isLogin: !!user,
        forresterButtonText: "免费阅读报告",
        onLoginSuccess: handleForresterSuccess,
        onSignedClick: onSignedClick,
      },
      dom
    )
  }

  useEffect(() => {
    if (!isBrowser()) {
      console.log('非浏览器环境，该 hook 只能在浏览器环境使用')
      return;
    }
    // 显示登录框
    showLogin()
  },[user])

  return (
    <>
      <Seo
        title={data?.title}
        description={data?.description}
        keywords={data?.keywords}
        saTitle={data?.saTitle}
      />
      <main className={classnames(styles.Report, 'hidden lg:block')}>
        <div className={styles.Container}>
          <div className={styles.ContentWrap}>
            <div className={styles.Content}>
              <div 
                className="mt-[50px] ml-[29px] text-[30px] leading-[42px] color-[#2C2C45] font-medium whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: data?.forresterTitle }}
              />
              <div 
                className="mt-[20px] ml-[29px] text-[14px] leading-[20px] color-[#2C2C45] whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: data?.forresterDesc }}
              />
              <img src={data?.forresterImg} className="mt-[25px]" alt="" />
            </div>
            <div className={styles.LoginWrap}>
              <div id="forester_login"></div>
            </div>
          </div>
        </div>
      </main>
      <main className={classnames(styles.ReportMb, 'block lg:hidden')}>
        <div className={classnames(styles.Container, {[styles.HiddenContainer]:!!user})}>
          <div className={styles.Content}>
            <img src={data?.forresterImgMb} className="mb-[2.6rem] w-full" alt="" />
            <div 
              className="text-[1.4rem] leading-[2rem] color-[#2C2C45] font-medium whitespace-pre-wrap mb-[3rem]"
              dangerouslySetInnerHTML={{ __html: data?.forresterTitleMb }}
            />
          </div>
          <div>
            <div id="forester_login_mb"></div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Report;
